import React, { useState, useEffect } from "react";
import "../../styles/Settings.css";
import PasswordSettings from "./PasswordSettings";
import Modal from "react-modal";
import axios from "axios";
import companyLogo from "../../img/organization.png"; // Default company logo
import { useContext } from "react";
import AuthContext from "../../AuthContext";
import { BASE_URL } from "../../config";

Modal.setAppElement("#root"); // Avoid accessibility warning from Modal

export default function Settings() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editField, setEditField] = useState(""); // To control which field is being edited
  const [userDetails, setUserDetails] = useState({});
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [newCompanyLogo, setNewCompanyLogo] = useState(null);
  const [error, setError] = useState("");
  const { userToken, userEmail, userRole } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/${userRole}/getUser`,
          { userEmail },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status === 1) {
          setUserDetails(response.data.data);
          setNewCompanyLogo(response.data.url);
          setNewCompanyName(response.data.data.company_name);
          setNewPhoneNumber(response.data.data.phone);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("Failed to fetch user details");
      }
    };
    fetchUserDetails();
  }, [userToken, userEmail, userRole]);

  const openModal = (field) => {
    setEditField(field);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleFileChange = (e) => {
    setNewCompanyLogo(e.target.files[0]);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("company_name", newCompanyName);
      formData.append("phone", newPhoneNumber);
      formData.append("userEmail", userEmail);
      if (newCompanyLogo) {
        formData.append("file", newCompanyLogo);
      }
      const response = await axios.post(
        `${BASE_URL}/${userRole}/updateUser`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === 1) {
        setUserDetails(response.data.data); // Update the state with new details
        setNewCompanyLogo(response.data.url);
        closeModal(); // Close the modal
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Failed to update user details");
    }
  };

  const handleUpdateUser = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/updateUser`,
        {
          company_name: newCompanyName,
          phone: newPhoneNumber,
          userEmail: userEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.data.status === 1) {
        setUserDetails(response.data.data); // Update the state with new details
        closeModal(); // Close the modal
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Failed to update user details");
    }
  };

  console.log(newCompanyLogo);

  console.log("823762t367t12", error);

  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-2" style={{ marginLeft: "0px" }}>
        <div className="settings-page pt-0">
          <div className="settings-item ">
            <div className="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-settings"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
              </svg>
              <p className="my-0 mx-2 settings_phead">Settings</p>
            </div>
          </div>
          <div
            className="d-none"
            style={{ display: "flex", alignItems: "center", gap: "1rem" }}
          >
            <img
              src={newCompanyLogo || companyLogo}
              alt="company logo"
              style={{ width: "30px", height: "30px" }}
            />
            <h2 className="m-0 set_page_hadiing">{userDetails.company_name}</h2>
          </div>

          <div className="settings-item ">
            <span>First name</span>
            <span className="fnt-capt">{userDetails.fname || "N/A"}</span>
          </div>

          <div className="settings-item ">
            <span>Last name</span>
            <span className="fnt-capt">{userDetails.lname || "N/A"}</span>
          </div>

          <div className="settings-item">
            <span>
              <span>Email</span>
            </span>
            <div>
              <span className="fnt-capt">{userDetails.email || "N/A"}</span>
              <button onClick={() => openModal("company_name")}>Edit</button>
            </div>
          </div>
          <div className="settings-item">
            <span>
              <span>Company Name</span>
            </span>
            <div>
              <span className="fnt-capt">
                {userDetails.company_name || "N/A"}
              </span>
            </div>
          </div>

          <div className="settings-item">
            <span>
              <span>Company logo</span>
            </span>
            <div>
              <img
                src={newCompanyLogo || companyLogo}
                alt="Company Logo"
                className="company-logo"
                style={{ width: "100px", height: "100px", borderRadius: "5px" }}
              />
              <button onClick={() => openModal("company_logo")}>Edit</button>
            </div>
          </div>

          <div className="settings-item">
            <span>Reset Password</span>
            <div>
              <button onClick={() => openModal("reset_password")}>
                Reset Password
              </button>
            </div>
          </div>

          {/* Modal for updating company name, phone number, and logo */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Overlay"
          >
            <button onClick={closeModal} className="close-modal p-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            {editField === "company_name" && (
              <div>
                <h2 className="modal-headings m-0">Edit Company Name</h2>
                <input
                  className="inptcmn wid300px mt-3"
                  type="text"
                  value={newCompanyName}
                  onChange={(e) => setNewCompanyName(e.target.value)}
                />
                <button className="go-back" onClick={handleUpdateUser}>
                  Update
                </button>
              </div>
            )}

            {editField === "phone" && (
              <div>
                <h2 className="modal-headings m-0">Edit Phone Number</h2>
                <input
                  type="text"
                  className="inptcmn"
                  value={newPhoneNumber}
                  onChange={(e) => setNewPhoneNumber(e.target.value)}
                />
                <button className="go-back" onClick={handleUpdateUser}>
                  Update
                </button>
              </div>
            )}

            {editField === "company_logo" && (
              <div>
                <h2 className="modal-headings mx-0 mt-0 mb-3">
                  Edit Company Logo
                </h2>
                <input
                  type="file"
                  className="inptcmn"
                  onChange={handleFileChange}
                />
                <button className="go-back" onClick={handleUpdate}>
                  Update
                </button>
              </div>
            )}

            {editField === "reset_password" && <PasswordSettings />}
          </Modal>
        </div>
      </div>
    </div>
  );
}
