import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import { BASE_URL } from "./config";
import "./styles/SimpleDocumentViewer.css";
import dayjs from "dayjs";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import useDebounce from "./components/useDebounce";
export default function SimpleDocumentView({ fileId, handleBack }) {
  const { userToken, userRole } = useContext(AuthContext);
  const [documentUrl, setDocumentUrl] = useState("");
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const [fileURL, setfileURL] = useState();
  const [companyDetails, setcompanyDetails] = useState("");
  const [note_list, setnote_list] = useState([]);
  const [NotesMSG, setNotesMSG] = useState(false);

  const [googleDocsViewerUrl, setgoogleDocsViewerUrl] = useState("");
  const debouncedQuery = useDebounce(notes, 500);
  useEffect(() => {
    if (debouncedQuery) {
      handleSaveNotes();
    }
  }, [debouncedQuery]);
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notesUrl = `${BASE_URL}/${userRole}/file/view/${fileId}`;

        const response = await axios.get(notesUrl, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200 && response.data?.notes) {
          setNotes(response.data.notes);
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        setError("Failed to fetch the notes");
      }
    };

    fetchDocument();
    // fetchNotes();
  }, [fileId, userToken, userRole]);
  const fetchDocument = async () => {
    try {
      const documentUrl =
        userRole === "user"
          ? `${BASE_URL}/user/file/view/${fileId}`
          : `${BASE_URL}/global/file/view/${fileId}`;

      const response = await axios.get(documentUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setnote_list(response.data.note_list);
        setDocumentUrl(response.data.url);
        setcompanyDetails(response.data);
        setgoogleDocsViewerUrl(
          `https://docs.google.com/gview?url=${response.data.url}&embedded=true`
        );
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setError("Failed to fetch the document");
    }
  };
  const handleSaveNotes = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/notes`,
        {
          file_id: fileId,
          notes: notes, // Current state of notes
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        setNotes("");
        setNotesMSG(true);
        setTimeout(() => {
          setNotesMSG(false);
        }, 2000);
        fetchDocument();
      } else {
        alert("Failed to save notes");
      }
    } catch (error) {
      console.error("Error saving notes:", error);
      setError("Failed to save notes");
    }
  };

  return (
    <div className="simple-document-view">
      <button className="go-back mx-4 mt-4" onClick={handleBack}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-arrow-left"
        >
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>{" "}
        &nbsp; Back to Documents
      </button>
      <div className="document-container">
        <div className="document-viewer">
          {/* {documentUrl ? (
            <iframe
              src={documentUrl}
              title="Document Viewer"
              width="100%"
              height="100%"
            ></iframe>
          ) : (
            <p>{error ? error : "Loading document..."}</p>
          )} */}
          {documentUrl && (
            <div style={{ height: "100vh", overflow: "auto" }}>
              {documentUrl.includes("pdf") && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={documentUrl} />
                </Worker>
              )}
              {documentUrl.includes("docx") && documentUrl && (
                <iframe
                  src={googleDocsViewerUrl}
                  style={{ width: "100%", height: "100vh" }}
                  frameBorder="0"
                />
              )}
            </div>
          )}
        </div>
        <div className="notes-section pt-2">
          <table className="tabl_dis_nts mt-0">
            <tr>
              <td>Document Name</td>
              <td>{companyDetails?.file?.name}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>{companyDetails?.file?.category_name}</td>
            </tr>
            <tr>
              <td>Submit Date</td>
              <td>
                {dayjs(companyDetails?.file?.submitted_date).format(
                  "DD MMM YYYY"
                )}
              </td>
            </tr>
            <tr>
              <td>Risk Score (%)</td>
              <td>
                {companyDetails?.file?.preview_risk_score &&
                  (companyDetails?.file?.preview_risk_score * 100).toFixed(2) +
                    "%"}
                {!companyDetails?.file?.preview_risk_score && "N/A"}
              </td>
            </tr>
            <tr>
              <td>Owned By</td>
              <td>
                {companyDetails?.user?.fname} {companyDetails?.user?.lname}
              </td>
            </tr>
          </table>

          <h3 className="notsheading mt-0">Notes:</h3>
          <textarea
            className="notes-textarea"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Write your notes here..."
          ></textarea>
          {NotesMSG && (
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-10px",
                color: "green",
              }}
            >
              saved
            </p>
          )}

          {note_list.length > 0 && (
            <ul style={{ listStyleType: "none", marginLeft: "-30px" }}>
              {note_list.map((item, key) => (
                <li key={key}>
                  <div className="inititab">
                    <span>{item.added_by[0]}</span>
                    <div className="mx-2">
                      <p>{item.added_by}</p>
                      <b>{item.note}</b>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
