import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../AuthContext";
import { BASE_URL } from "../../config";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import "../../styles/ReadLine.css";

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
};

const ReadLine = () => {
  const { userToken, userEmail, fname, lname } = useContext(AuthContext);
  const [activeChat, setActiveChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [readLineFiles, setReadLineFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [fileVersions, setFileVersions] = useState({});
  const [savedChats, setSavedChats] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedUserIdToAdd, setSelectedUserIdToAdd] = useState('');
  const [selectedUserIdToRemove, setSelectedUserIdToRemove] = useState('');
  const [chatMembers, setChatMembers] = useState([]);
  const [showAddRemoveMembers, setShowAddRemoveMembers] = useState(false);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/team-members`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });
      const { status, data } = response.data;

      if (status === 1) {
        const filteredData = data.filter((member) => member.email !== userEmail);
        setTeamMembers(filteredData);
      } else {
        console.error('Error fetching team members:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, [userToken]);

  const handleAddUserToChat = async (chatId, memberId) => {
    try {
      await axios.post(
        `${BASE_URL}/chat/${chatId}/addUser/${memberId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const addedMember = teamMembers.find((member) => member._id === memberId);
      if (addedMember) {
        setChatMembers((prev) => [...prev, addedMember]);
      }
      alert('User added to chat successfully!');
      setSelectedUserIdToAdd('');
    } catch (error) {
      console.error('Error adding user to chat:', error);
    }
  };

  const handleRemoveUserFromChat = async (chatId, memberId) => {
 
    try {
      await axios.post(
        `${BASE_URL}/chat/${chatId}/removeUser/${memberId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setChatMembers((prev) => prev.filter((member) => member._id !== memberId));
      setSelectedUserIdToRemove('');
      alert('User removed from chat successfully!');
    } catch (error) {
      console.error('Error removing user from chat:', error);
    }
  };

  const fetchChatMembers = async (chatId) => {
    try {
      const response = await axios.get(`${BASE_URL}/chat/${chatId}/members`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });

      setChatMembers(response.data.members);
    } catch (error) {
      console.error('Error fetching chat members:', error);
    }
  };

  useEffect(() => {
    if (chatId) {
      fetchChatMembers(chatId);
    }
  }, [chatId]);



  const fetchChatMessages = async (chatId) => {
    try {
      const response = await axios.get(`${BASE_URL}/chat/${chatId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });

      let userFullName = `${fname} ${lname}`;
      setMessages(
        response.data.chat.messages.map((msg) => {
          const sender = msg.sender === userFullName ? 'You' : msg.sender;
          return `${sender}: ${msg.message}`;
        })
      );
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  };

  const fetchFileVersionsForFile = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/file/versions/${fileId}`,
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      return response.data?.versions || [];
    } catch (error) {
      console.error('Error fetching file versions:', error);
      return [];
    }
  };

  useEffect(() => {
    let intervalId;
    if (activeChat) {
      intervalId = setInterval(async () => {
        if (chatId) {
          await fetchChatMessages(chatId);
        }
        if (selectedFile && selectedFile._id) {
          const fileVersions = await fetchFileVersionsForFile(selectedFile._id);
          setFileVersions((prev) => ({
            ...prev,
            [selectedFile._id]: fileVersions,
          }));
        }
      }, 2000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeChat, chatId, selectedFile]);

 

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await axios.post(
          `${BASE_URL}/chat/${chatId}/message`,
          { message: newMessage },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setMessages((prev) => [...prev, `You: ${newMessage}`]);
        setNewMessage('');
      } catch (error) {
        alert(error.response?.data?.message || 'Error sending message');
        console.error('Error sending message:', error);
      }
    }
  };

// ReadLine.js

const handleChatRequest = async (file) => {
  setSelectedFile(file);

  try {
    const response = await axios.post(
      `${BASE_URL}/chat`,
      { fileId: file._id },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.status === 1 || response.data.status === 2) {
      // Chat successfully created or existing chat returned
      resumeChat(response.data.chat);
    }
  } catch (error) {
    console.error('Error creating or joining chat:', error);

    if (error.response?.data?.status === 1) {
      // User is not authorized to join the chat
      alert(error.response.data.message);
    } else {
      // General error
      alert('Error creating or joining chat.');
    }
  }
};

const resumeChat = async (chat) => {
  setChatId(chat._id);
  setSelectedFile(readLineFiles.find((file) => file._id === chat.fileId));
  setActiveChat(true);
  await fetchChatMessages(chat._id);
  await fetchChatMembers(chat._id);
};

  const handleCloseChat = () => {
    setActiveChat(false);
    setMessages([]);
    setSelectedFile(null);
    setSelectedVersion(null);
    setShowAddRemoveMembers(false);
    setChatMembers([]);
  };

  useEffect(() => {
    fetchReadLineFiles();
  }, [activeChat]);

  const fetchReadLineFiles = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/file/readlineFiles`,
        { page: '1', size: '10' },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const files = response.data?.data || [];
      setReadLineFiles(files);
    } catch (error) {
      console.error('Error fetching ReadLine files:', error);
    }
  };

  const fetchAllFileVersions = async () => {
    try {
      const versionPromises = readLineFiles.map(async (file) => {
        const response = await axios.get(
          `${BASE_URL}/user/file/versions/${file._id}`,
          { headers: { Authorization: `Bearer ${userToken}` } }
        );
        return { fileId: file._id, versions: response.data?.versions || [] };
      });

      const versionsData = await Promise.all(versionPromises);

      const updatedVersions = {};
      versionsData.forEach(({ fileId, versions }) => {
        updatedVersions[fileId] = versions;
      });

      setFileVersions(updatedVersions);
    } catch (error) {
      console.error('Error fetching file versions:', error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      await fetchReadLineFiles();
      setLoading(false);
    };

    initializeData();
  }, [userToken]);

  useEffect(() => {
    if (readLineFiles.length > 0) {
      fetchAllFileVersions();
    }
  }, [readLineFiles]);

  const handleVersionChange = (file, selectedVersionId) => {
    if (selectedVersionId === file._id) {
      setSelectedVersion(null);
      setReadLineFiles((prevFiles) =>
        prevFiles.map((f) =>
          f._id === file._id ? { ...f, selectedVersion: null } : f
        )
      );
    } else {
      const selectedVer = fileVersions[file._id]?.find(
        (v) => v._id === selectedVersionId
      );
      setSelectedVersion(selectedVer);
      setReadLineFiles((prevFiles) =>
        prevFiles.map((f) =>
          f._id === file._id ? { ...f, selectedVersion: selectedVer } : f
        )
      );
    }
  };

  const handleFileChange = async (event) => {
    if (
      !window.confirm(
        `Are you sure you want to upload ${event.target.files[0].name} as a version for ${selectedFile.filename}?`
      )
    ) {
      return;
    }
    const file = event.target.files[0];
    if (!file || !selectedFile) {
      alert('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('original_file_id', selectedFile._id);

    try {
      const response = await axios.post(
        `${BASE_URL}/user/file/upload-new-version`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data.status === 1) {
        alert('File uploaded successfully!');
        const newVersion = response.data.version;
        setFileVersions((prev) => ({
          ...prev,
          [selectedFile._id]: [...(prev[selectedFile._id] || []), newVersion],
        }));
      } else {
        alert(response.data.message || 'File upload failed.');
      }
    } catch (error) {
      console.error('Error uploading new version:', error);
    }
  };

  const handleDownloadVersion = async (version) => {
    try {
      const versionId = version._id;
      const url = `${BASE_URL}/user/downloadVersion?version_id=${versionId}`;
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${userToken}` },
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', version.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading version:', error);
    }
  };

  return (
    <div
      className="readline-container"
      style={{
        display: 'flex',
        flexDirection: !activeChat ? 'column' : 'row',
        alignItems: !activeChat && 'center',
      }}
    >
      {activeChat && (
        <div className="chat-window">
          <div className="chat-header">
            <button className="go-back mx-4 mt-4" onClick={handleCloseChat}>
              ← Back
            </button>
            <h3>
          Chat with Admin about Document: {selectedFile?.filename?.length > 20 
            ? `${selectedFile.filename.substring(0, 20)}...pdf` 
            : selectedFile?.filename}
        </h3>
            <span>
              Version: {selectedVersion?.filename || selectedFile?.filename}
            </span>
          </div>
          <div className="chat-messages">
            {messages.length > 0 ? (
              messages.map((msg, index) => <p key={index}>{msg}</p>)
            ) : (
              <p>No messages yet</p>
            )}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Enter your message"
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}

      {/* File list display */}
      <div className="files-list" style={{ width: !activeChat && '100%' }}>
        <h5 className="cards_headings">
          {activeChat ? 'Versions' : 'Redline Files'}
        </h5>
        {loading ? (
          <BeatLoader
            color={'#000'}
            loading={loading}
            cssOverride={override}
            size={10}
          />
        ) : activeChat ? (
          // Render versions table when activeChat is true
          <table className="task_tabl">
            <thead>
              <tr>
                <th>Version Name</th>
                <th>Version Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr key={selectedFile._id}>
                <td>{selectedFile.filename} (Original)</td>
                <td>1</td>
                <td>
                  <button 
                  className="readline-btn"
                  onClick={() => handleDownloadVersion(selectedFile)}>
                    Download
                  </button>
                  <button
                  className="readline-btn"
                  style = {{marginTop: '0.5rem'}}
                    onClick={() =>
                      handleVersionChange(selectedFile, selectedFile._id)
                    }
                  >
                    {selectedVersion?._id === selectedFile._id ||
                    !selectedVersion
                      ? 'Selected'
                      : 'Select'}
                  </button>
                </td>
              </tr>
              {fileVersions[selectedFile._id] &&
                fileVersions[selectedFile._id].map((version, index) => (
                  <tr key={version._id}>
                    <td>{version.filename}</td>
                    <td>{index + 2}</td>
                    <td>
                      <button 
                      className="readline-btn"
                      onClick={() => handleDownloadVersion(version)}>
                        Download
                      </button>
                      <button
                      className="readline-btn"
                      style= {{marginTop: '0.5rem'}}
                        onClick={() =>
                          handleVersionChange(selectedFile, version._id)
                        }
                      >
                        {selectedVersion?._id === version._id
                          ? 'Selected'
                          : 'Select'}
                      </button>
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan="3">
                <div className="d-flex flex-column justify-content-between" style={{ gap: '0.5rem' }}>
                    <div
                      className="upload-new-version"
                      style={{ display: 'flex' }}
                    >
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <button
                        className="readline-btn"
                        onClick={() =>
                          document.getElementById('fileInput').click()
                        }
                      >
                        Upload New Version
                      </button>
                    </div>
                    <div
                      className="upload-new-version"
                      style={{ display: 'flex' }}
                    >
                      <button
                        className="readline-btn"
                        onClick={() =>
                          setShowAddRemoveMembers(!showAddRemoveMembers)
                        }
                      >
                        Add/Remove Members
                      </button>
                    </div>
                  </div>
                  {showAddRemoveMembers && (
                    <div
                      className="add-remove-members"
                      style={{ marginTop: '10px', display: 'flex', gap: '1rem' }}
                    >
                      <div
                        className="upload-new-version"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <select
                          value={selectedUserIdToAdd}
                          onChange={(e) =>
                            setSelectedUserIdToAdd(e.target.value)
                          }
                          className="select-member"
                        >
                          <option value="">Select user to add</option>
                          {teamMembers
                            .filter(
                              (member) =>
                                !chatMembers.some(
                                  (cm) => cm._id === member._id
                                )
                            )
                            .map((member) => (
                              <option key={member._id} value={member._id}>
                                {member.fname} {member.lname}
                              </option>
                            ))}
                        </select>
                        <button
                          className="readline-btn"
                          onClick={() =>
                            handleAddUserToChat(chatId, selectedUserIdToAdd)
                          }
                          style={{ marginLeft: '10px' }}
                        >
                          Add
                        </button>
                      </div>
                      <div
                        className="upload-new-version"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <select
                          value={selectedUserIdToRemove}
                          onChange={(e) =>
                            setSelectedUserIdToRemove(e.target.value)
                          }
                          className="select-member"
                        >
                          <option value="">Select user to remove</option>
                          {chatMembers
                            .filter((member) => member.email !== userEmail)
                            .map((member) => (
                              <option key={member._id} value={member._id}>
                                {member.fname} {member.lname}
                              </option>
                            ))}
                        </select>
                        <button
                          className="readline-btn"
                          onClick={() =>
                            handleRemoveUserFromChat(
                              chatId,
                              selectedUserIdToRemove
                            )
                          }
                          style={{ marginLeft: '10px' }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : readLineFiles.length === 0 ? (
          <p>No records found.</p>
        ) : (
          <table className="task_tabl">
            <thead>
              <tr>
                <th>Document Name</th>
                <th>Category</th>
                <th>Added date</th>
                <th>Versions</th>
                {!activeChat && <th>Chat</th>}
              </tr>
            </thead>
            <tbody className="redtabletd">
              {readLineFiles.map((file) => (
                <tr key={file._id}>
                  <td>{file.filename}</td>
                  <td>{file.category_name || 'N/A'}</td>
                  <td>
                    {new Date(
                      file.date_added_to_readline
                    ).toLocaleDateString()}
                  </td>
                  <td className="redlineselct">
                    <select
                      style={{ width: '100%' }}
                      value={file.selectedVersion?._id || file._id}
                      disabled={
                        !activeChat || selectedFile?._id !== file._id
                      }
                      onChange={(e) =>
                        handleVersionChange(file, e.target.value)
                      }
                    >
                      <option value={file._id}>{file.filename}</option>
                      {fileVersions[file._id] &&
                        fileVersions[file._id].map((version) => (
                          <option key={version._id} value={version._id}>
                            {version.filename}
                          </option>
                        ))}
                    </select>
                  </td>
                  {!activeChat && (
                    <td>
                      <button
                      className="readline-btn"
                        onClick={() => handleChatRequest(file)}
                      >
                        Chat
                      </button>
                    </td>
                  )}
                </tr>
              ))}

              <tr>
                <td colSpan={5}>
                  {activeChat && (
                    <div className="d-flex justify-content-between">
                      <div
                        className="upload-new-version"
                        style={{ display: "flex" }}
                      >
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <button
                          className="readline-btn"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          Upload New Version
                        </button>
                      </div>
                      <div
                        className="upload-new-version"
                        style={{ display: "flex" }}
                      >
                        <button
                          className="readline-btn"
                          onClick={(e) =>
                            handleDownloadVersion(
                              selectedVersion || selectedFile
                            )
                          }
                        >
                          Download selected version
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

 
    </div>
  );
};

export default ReadLine;