import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AuthContext from "../AuthContext";
import ChartTwo from "./ChartTwo";
import ChartThree from "./ChartThree";
import { BASE_URL } from "../config";
import ReusableChart from "./ReusableChart";
import "../styles/main.css";
import MonthlyRiskScores from "./MonthlyRiskScores";
import AverageMonthlyRiskScores from "./AverageMonthlyRiskScores";
import Header from "./Header";
import CategoryGraph from "./CategoryGraph";
import GroupingGraph from "./GroupingGraph";
import { BeatLoader } from "react-spinners";
import useDebounce from "./useDebounce";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const Report = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [buyerSeller, setBuyerSeller] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [discussionPoint, setDiscussionPoint] = useState("");
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");
  const { userToken } = useContext(AuthContext);
  const [fileDetailsList, setfileDetailsList] = useState([]);
  const [isChart, setisChart] = useState(true);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [currentColumn, setcurrentColumn] = useState("");
  const [currentAction, setcurrentAction] = useState("");

  const [documentCounts, setDocumentCounts] = useState({
    nda: 0,
    msa: 0,
    dpa: 0,
  });

  useEffect(() => {
    const fetchDocumentCounts = async () => {
      try {
        const responseNDA = await axios.post(
          `${BASE_URL}/user/files/getDocumentCountForCategoryThisMonth`,
          { categoryName: "Non Disclosure Agreement (NDA)" },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const responseMSA = await axios.post(
          `${BASE_URL}/user/files/getDocumentCountForCategoryThisMonth`,
          { categoryName: "Master Services Agreement (MSA)" },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const responseDPA = await axios.post(
          `${BASE_URL}/user/files/getDocumentCountForCategoryThisMonth`,
          { categoryName: "Data Processing Agreement (DPA)" },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        setDocumentCounts({
          nda:
            responseNDA.data.status === 1
              ? responseNDA.data.data.documentCount
              : 0,
          msa:
            responseMSA.data.status === 1
              ? responseMSA.data.data.documentCount
              : 0,
          dpa:
            responseDPA.data.status === 1
              ? responseDPA.data.data.documentCount
              : 0,
        });
      } catch (error) {
        console.error("Failed to fetch document counts:", error);
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/category/list`,
          {
            page: "0",
            size: "10",
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.status === 1) {
          setCategories(response.data.categories);
        } else {
          alert(response.data?.data?.message || "Failed to fetch categories");
        }
      } catch (error) {
        alert("Failed to fetch categories");
      }
    };
    fetchCategories();
    fetchDocumentCounts();
  }, [userToken]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchFilesDetails();
    }
  };
  useEffect(() => {
    // if (debouncedQuery) {
    fetchFilesDetails();
    // }
  }, [currentColumn, currentAction]);
  const fetchFilesDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/task/file/details`,
        {
          keyword: query,
          sort: {
            column: currentColumn,
            action: currentAction,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        console.log(response.data);
        setfileDetailsList(response.data.data);
        // setCategories(response.data.categories);
      }
    } catch (error) {
      alert("Failed to fetch categories");
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    if (event.target.files[0]) {
      setShowModal(true);
    }
  };

  const handleUpload = async () => {
    if (selectedFile && buyerSeller && selectedCategory) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const uploadResponse = await axios.post(
          `${BASE_URL}/global/upload-file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse.data.status === 1) {
          const { filename, original_filename } = uploadResponse.data;

          const secondResponse = await axios.post(
            `${BASE_URL}/user/upload-and-search`,
            {
              filename,
              original_filename,
              user_type: buyerSeller,
              qc_uid: selectedCategory,
              discussion_point: discussionPoint,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (secondResponse.data.status === 1) {
            alert("File uploaded and processed successfully!");
            handleCloseModal();
          } else {
            setError(
              secondResponse.data.message ||
                "An error occurred during the second upload step."
            );
          }
        } else {
          setError(
            uploadResponse.data.message ||
              "An error occurred during the file upload."
          );
        }
      } catch (error) {
        setError("An error occurred during file upload");
      }
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setSelectedFile(null);
    setBuyerSeller("");
    setSelectedCategory("");
    setDiscussionPoint("");
    setShowModal(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-3">
          <header className="header-flex">
            <div></div>
            <Header />
          </header>

          <div>
            <div className="container-fluid mt-5">
              <div className="row">
                <div className="col-6 "></div>
                <div className="col-6" style={{ marginTop: "-30px" }}>
                  <div class="container_chx">
                    <input
                      type="checkbox"
                      onClick={() => setisChart(!isChart)}
                      class="checkbox_chx"
                      id="checkbox"
                    />
                    <label class="switch_chx" for="checkbox">
                      <span class="slider_chx"></span>
                    </label>
                    <label for="checkbox" className="charts_nms">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bar-chart-2"
                      >
                        <line x1="18" y1="20" x2="18" y2="10"></line>
                        <line x1="12" y1="20" x2="12" y2="4"></line>
                        <line x1="6" y1="20" x2="6" y2="14"></line>
                      </svg>
                    </label>
                    <label for="checkbox" className="files_nms">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-file"
                      >
                        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                        <polyline points="13 2 13 9 20 9"></polyline>
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
              {!isChart && (
                <div className="row ">
                  <div className="col-12 ">
                    <div className="cards">
                      <div className="row">
                        <div className="col-6 px-2" style={{ display: "flex" }}>
                          <button
                            className="shortanswer"
                            onClick={() => {
                              setQuery("");
                              setcurrentColumn("");
                              setcurrentAction("");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="grey"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-refresh-ccw"
                            >
                              <polyline points="1 4 1 10 7 10"></polyline>
                              <polyline points="23 20 23 14 17 14"></polyline>
                              <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                            </svg>
                          </button>
                          &nbsp;
                          <label className="position-relative search-header">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-search"
                            >
                              <circle cx="11" cy="11" r="8"></circle>
                              <line
                                x1="21"
                                y1="21"
                                x2="16.65"
                                y2="16.65"
                              ></line>
                            </svg>
                            <input
                              type="text"
                              value={query}
                              onKeyUp={handleKeyPress}
                              onChange={(e) => {
                                setQuery(e.target.value);
                              }}
                              placeholder="Search Files"
                            />
                          </label>
                        </div>
                      </div>
                      <table className="rpt_tables mt-2">
                        <tbody>
                          <tr>
                            <th>
                              <div className="d-flex">
                                <input type="checkbox"></input>
                              </div>
                            </th>
                            <th>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="grey"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              &nbsp;&nbsp; Name
                            </th>
                            <th>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-calendar"
                                  >
                                    <rect
                                      x="3"
                                      y="4"
                                      width="18"
                                      height="18"
                                      rx="2"
                                      ry="2"
                                    ></rect>
                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                  </svg>
                                  &nbsp;&nbsp; Risk Score
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    cursor: "pointer",
                                  }}
                                >
                                  <svg
                                    onClick={() => {
                                      setcurrentColumn("risk_score");
                                      setcurrentAction("asc");
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-down"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                  <svg
                                    onClick={() => {
                                      setcurrentColumn("risk_score");
                                      setcurrentAction("desc");
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-up"
                                  >
                                    <polyline points="18 15 12 9 6 15"></polyline>
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-file"
                                  >
                                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                    <polyline points="13 2 13 9 20 9"></polyline>
                                  </svg>
                                  &nbsp;&nbsp; ACV
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    cursor: "pointer",
                                  }}
                                >
                                  <svg
                                    onClick={() => {
                                      setcurrentColumn("acv");
                                      setcurrentAction("asc");
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-down"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                  <svg
                                    onClick={() => {
                                      setcurrentColumn("acv");
                                      setcurrentAction("desc");
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-up"
                                  >
                                    <polyline points="18 15 12 9 6 15"></polyline>
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-file-text"
                              >
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                <polyline points="14 2 14 8 20 8"></polyline>
                                <line x1="16" y1="13" x2="8" y2="13"></line>
                                <line x1="16" y1="17" x2="8" y2="17"></line>
                                <polyline points="10 9 9 9 8 9"></polyline>
                              </svg>
                              &nbsp;&nbsp; Payment Terms
                            </th>
                            <th>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-calendar"
                              >
                                <rect
                                  x="3"
                                  y="4"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                <line x1="3" y1="10" x2="21" y2="10"></line>
                              </svg>
                              &nbsp;&nbsp; Contract start date
                            </th>
                          </tr>
                        </tbody>
                        <tbody>
                          {loading && (
                            <tr>
                              <td colSpan={6}>
                                <BeatLoader
                                  color={"#000"}
                                  loading={loading}
                                  cssOverride={override}
                                  size={10}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          )}
                          {!loading && fileDetailsList.length == 0 && (
                            <tr>
                              <td colSpan={5}>
                                <p>No files found.</p>
                              </td>
                            </tr>
                          )}
                          {!loading &&
                            fileDetailsList.length > 0 &&
                            fileDetailsList.map((item, key) => (
                              <tr key={key}>
                                <td>
                                  <div className="d-flex">
                                    <input type="checkbox"></input>
                                  </div>
                                </td>
                                <td>{item.filename}</td>
                                <td>
                                  {item.details.risk_score &&
                                    (item.details.risk_score * 100).toFixed(2) +
                                      "%"}
                                  {/* {item.details.risk_score &&
                                    item.details.risk_score + "%"}*/}
                                  {!item.details.risk_score && "N/A"}
                                </td>
                                <td>{item.details.acv}</td>
                                <td>{item.details.payment_terms}</td>
                                <td>{item.details.contract_start_date}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {isChart && (
                <>
                  <div className="row">
                    <div className="col">
                      {documentCounts.nda > 0 && (
                        <div className="cards p-4 pb-0">
                          <p className="font-13 color-l-grey mb-1">
                            Documents in NDA
                          </p>
                          <ReusableChart
                            categoryName="Non Disclosure Agreement (NDA)"
                            color="#f54d05"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col">
                      {documentCounts.msa > 0 && (
                        <div className="cards p-4 pb-0">
                          <p className="font-13 color-l-grey mb-1">
                            Documents in MSA
                          </p>
                          <ReusableChart
                            categoryName="Master Services Agreement (MSA)"
                            color="#75ff70"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col">
                      {documentCounts.dpa > 0 && (
                        <div className="cards p-4 pb-0">
                          <p className="font-13 color-l-grey mb-1">
                            Documents in DPA
                          </p>
                          <ReusableChart
                            categoryName="Data Processing Agreement (DPA)"
                            color="#9C27B0"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="cards" style={{ width: "80vw" }}>
                        <ChartTwo />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5 pb-5">
                    <div className="col-12">
                      <div className="cards" style={{ width: "80vw" }}>
                        <ChartThree />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5 pb-5">
                    <div className="col-12">
                      <div className="cards" style={{ width: "80vw" }}>
                        <MonthlyRiskScores categories={categories} />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5 pb-5">
                    <div className="col-12">
                      <div className="cards" style={{ width: "80vw" }}>
                        <AverageMonthlyRiskScores />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5 pb-5">
                    <div className="col-12">
                      <div className="cards" style={{ width: "80vw" }}>
                        <CategoryGraph />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5 pb-5">
                    <div className="col-12">
                      <div className="cards" style={{ width: "80vw" }}>
                        <GroupingGraph />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Upload Modal */}
      {showModal && (
        <>
          <div
            className="modal fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
          >
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Upload Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={handleCloseModal}
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label className="file-upload-label mt-3">
                          <div className="upload-drag">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="44"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#6b7280"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-upload"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="17 8 12 3 7 8"></polyline>
                              <line x1="12" y1="3" x2="12" y2="15"></line>
                            </svg>
                            <p className="upload-text font-13 w-700 mb-0 mt-3">
                              {selectedFile
                                ? selectedFile.name
                                : "Choose file to upload or drag and drop"}
                            </p>
                            <span className="upload-limit font-11">
                              Only *.xls, *.xlsx, *.csv, *.ods, *.pdf & 30 MB
                              limit
                            </span>
                          </div>
                          <input
                            type="file"
                            className="file-input"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="form-group report_selects2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <label>Buyer/Seller:</label>

                          <select
                            className="mt-0 mb-0"
                            value={buyerSeller}
                            onChange={(e) => setBuyerSeller(e.target.value)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group report_selects2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <label>Category:</label>
                          <select
                            value={selectedCategory}
                            onChange={(e) =>
                              setSelectedCategory(e.target.value)
                            }
                            required
                            className="mt-0 mb-0"
                          >
                            <option value="">Select</option>
                            {Array.isArray(categories) &&
                              categories.map((category) => (
                                <option
                                  key={category.qc_uid}
                                  value={category.qc_uid}
                                >
                                  {category.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Discussion Point:</label>
                          <input
                            value={discussionPoint}
                            onChange={(e) => setDiscussionPoint(e.target.value)}
                            placeholder="Enter any points you want to discuss"
                            required
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          className="btn_uplo_doc mx-auto my-0"
                          aria-label="Upload Documents"
                          onClick={handleUpload}
                        >
                          Upload Documents
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white position-relative d-none">
                    {/* <label className="md_lbl mt-4" htmlFor="file_sel">
                      <div className="text-center upload_drag">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="44"
                          height="44"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#6b7280"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-upload"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        <p className="font-13 w-700 mb-0 mt-3">
                          Choose file to upload or drag and drop
                        </p>
                        <span className="font-11">
                          Only *.xls, *.xlsx, *.csv, *.ods, *.pdf & 30 MB limit
                        </span>
                      </div>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={handleCloseModal}
          ></div>
        </>
      )}
    </>
  );
};

export default Report;
