// import React, { useState, useContext } from 'react'
// import axios from 'axios'
// import AuthContext from '../AuthContext'
// import '../styles/Auth.css'

// const BASE_URL = 'http://localhost:8089/v1'
// // const BASE_URL = 'https://modinsight.dev:8089/v1'
// // const BASE_URL = 'http://18.190.103.35:8089/v1'

// export default function SignIn() {
//   const [email, setEmail] = useState('')
//   const [password, setPassword] = useState('')
//   const [error, setError] = useState('')

//   const { login } = useContext(AuthContext)

//   const handleSubmit = async (e) => {
//     e.preventDefault()

//     try {
//       const response = await axios.post(`${BASE_URL}/user/login`, {
//         email,
//         password,
//       })
//       if (response.data.status === 1) {
//         const { token, role } = response.data.data
//         console.log(role)
//         login(email, token, role)
//       } else {
//         setError(response.data.message)
//       }
//     } catch (err) {
//       setError('Failed to sign in')
//     }
//   }

//   return (
//     <div className="auth-container">
//       <h2>Sign In</h2>
//       {error && <p className="error">{error}</p>}
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label>Email:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Sign In</button>
//       </form>
//     </div>
//   )
// }

import React, { useState, useContext } from 'react'
import axios from 'axios'
import logo from '../img/logo.svg'
import { Link } from 'react-router-dom'
import AuthContext from '../AuthContext'
import { BASE_URL } from '../config'
import '../styles/Auth.css'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const { login } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(`${BASE_URL}/user/login`, {
        email,
        password,
      })
      if (response.data.status === 1) {
        const { token, role, userId, fname, lname } = response.data.data
        login(email, token, role, userId, fname, lname)
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to sign in')
    }
  }

  return (
    <div className=" pt-0">
      <img className="logo mb-0" src={logo} alt="modInsight" />
      <h2 className='login-heading'>Log In</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter email.."
          />
        </div>
        <div className="form-group mb-2">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Enter password.."
          />
        </div>
        <div className='d-flex align-items-center mb-2 pb-2 mt-2'>
          <div className='pt-1 me-2'>
            <input className='m-0' type="checkbox" required />
          </div>
          <div>
          <span style={{ fontSize: '12px' }}>
            Agree to <Link to="/terms">Terms & Conditions</Link> and{' '}
            <Link to="/privacy">Privacy Policy</Link>
          </span>
          </div>
        </div>
        <div>
        <button type="submit" className="auth-btn m-0">
          Log In
        </button>
        </div>
       
        
      </form>
    </div>
  )
}
