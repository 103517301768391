// import React, { useState, useContext } from 'react'
// import axios from 'axios'
// import AuthContext from '../AuthContext'
// import '../styles/Auth.css'

// const BASE_URL = 'http://localhost:8089/v1'
// // const BASE_URL = 'https://modinsight.dev:8089/v1'
// // const BASE_URL = 'http://18.190.103.35:8089/v1'

// export default function SignUp() {
//   const [email, setEmail] = useState('')
//   const [confirmEmail, setConfirmEmail] = useState('')
//   const [password, setPassword] = useState('')
//   const [confirmPassword, setConfirmPassword] = useState('')
//   const [fname, setFname] = useState('')
//   const [lname, setLname] = useState('')
//   const [companyName, setCompanyName] = useState('')
//   const [error, setError] = useState('')

//   const { login } = useContext(AuthContext)

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     if (email !== confirmEmail) {
//       setError('Emails do not match')
//       return
//     }
//     if (email.endsWith('@gmail.com')) {
//       setError('Email cannot be @gmail.com')
//       return
//     }
//     if (password !== confirmPassword) {
//       setError('Passwords do not match')
//       return
//     }

//     try {
//       const response = await axios.post(`${BASE_URL}/user/register`, {
//         email,
//         confirm_email: confirmEmail,
//         password,
//         confirm_password: confirmPassword,
//         fname,
//         lname,
//         company_name: companyName,
//       })
//       console.log(response)
//       if (response.data.status === 1) {
//         // const token = response.data.data.token
//         console.log(response.data.data)
//         const { token, role } = response.data.data
//         login(email, token, role)
//       } else {
//         setError(response.data.message)
//       }
//     } catch (err) {
//       setError('Failed to sign up')
//     }
//   }

//   return (
//     <div className="auth-container">
//       <h2>Sign Up</h2>
//       {error && <p className="error">{error}</p>}
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <div className="side-by-side">
//             <div className="half-width">
//               <label>First Name:</label>
//               <input
//                 type="text"
//                 value={fname}
//                 onChange={(e) => setFname(e.target.value)}
//                 required
//               />
//             </div>
//             <div className="half-width">
//               <label>Last Name:</label>
//               <input
//                 type="text"
//                 value={lname}
//                 onChange={(e) => setLname(e.target.value)}
//                 required
//               />
//             </div>
//           </div>
//         </div>
//         <div className="form-group">
//           <label>Company Name:</label>
//           <input
//             type="text"
//             value={companyName}
//             onChange={(e) => setCompanyName(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Email:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Confirm Email:</label>
//           <input
//             type="email"
//             value={confirmEmail}
//             onChange={(e) => setConfirmEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Confirm Password:</label>
//           <input
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Sign Up</button>
//       </form>
//     </div>
//   )
// }

import React, { useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import logo from '../img/logo.svg'
import { BASE_URL } from '../config'
import '../styles/Auth.css'

export default function SignUp() {
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [error, setError] = useState('')

  const { login } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (email !== confirmEmail) {
      setError('Emails do not match')
      return
    }
    if (email.endsWith('@gmail.com')) {
      setError('Email cannot be @gmail.com')
      return
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match')
      return
    }

    try {
      const response = await axios.post(`${BASE_URL}/user/register`, {
        email,
        confirm_email: confirmEmail,
        password,
        confirm_password: confirmPassword,
        fname,
        lname,
        company_name: companyName,
      })
      if (response.data.status === 1) {
        const { token, role, userId, fname, lname } = response.data.data
        login(email, token, role, userId, fname, lname)
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to sign up')
    }
  }

  return (
    <div>
      <img className="logo mb-0" src={logo} alt="modInsight" />
      <h4 className='signup-text'>
        Welcome to <b>ModCounsel!</b><br></br>Helping growth companies take smart risks
      </h4>
      <h2 className='login-heading mt-3 mb-4'>Sign Up</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="side-by-side">
            <div className="half-width">
              <label>First Name:</label>
              <input
                type="text"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                required
                placeholder="First Name"
              />
            </div>
            <div className="half-width">
              <label>Last Name:</label>
              <input
                type="text"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
                required
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Company Name:</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            placeholder="Company Name"
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Business Email"
          />
        </div>
        <div className="form-group">
          <label>Confirm Email:</label>
          <input
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
            placeholder="Confirm Email"
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </div>
        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder="Confirm Password"
          />
        </div>
        <div className="d-flex align-items-center">
          
            <input type="checkbox" className='me-2' required />{' '}
        
          <span style={{ fontSize: '12px' }}>
            Agree to <a href="/terms">Terms & Condition</a> and{' '}
            <a href="/privacy">Privacy Policy</a>
          </span>
        </div>
        <button type="submit" className="auth-btn mx-0">
          Sign Up
        </button>
      </form>
    </div>
  )
}
