let BASE_URL = "";

if (process.env.NODE_ENV !== "development") {
  // Use production URL
  BASE_URL = "https://modinsight.dev:8089/v1";
} else {
  // Use development URL
  //BASE_URL = "https://modinsight.dev:8089/v1"; /*localhost*/
  // BASE_URL = "https://modinsight.dev:8089/v1"; /*localhost*/
  BASE_URL = "https://modinsight.dev:8089/v1";
}

export { BASE_URL };
