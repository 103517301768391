import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../../AuthContext";
import "../../styles/Documents.css";
import SimpleDocumentView from "../../SimpleDocumentViewer";
import { BASE_URL } from "../../config";
import Pagination from "../Pagination";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const Documents = () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFileId, setSelectedFileId] = useState(null); // State to track the selected file
  const { userToken, userRole } = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchFiles(page);
  };
  useEffect(() => {
    const isSearchUser = localStorage.getItem("isSearchUser");
    if (isSearchUser) {
      setSelectedFileId(isSearchUser);
      localStorage.removeItem("isSearchUser");
    }

    fetchFiles();
  }, [userToken, userRole]);
  const fetchFiles = async (current) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/allFiles/list`,
        {
          page: current,
          size: "10",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 1) {
        console.log(response.data);
        settotalPages(response.data.totalPages);
        setFiles(response.data.data);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      setError("Failed to fetch files");
    } finally {
      setLoading(false);
    }
  };
  const handleViewFile = (fileId) => {
    setSelectedFileId(fileId); // Set the selected file ID
  };

  const handleBack = () => {
    setSelectedFileId(null); // Reset to go back to the documents list
  };

  if (selectedFileId) {
    // If a file is selected, show the SimpleDocumentView component
    return (
      <SimpleDocumentView fileId={selectedFileId} handleBack={handleBack} />
    );
  }

  // if (loading) {
  //   return <p>Loading files...</p>;
  // }

  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <div className=" px-4">
      <h2 className="mx-0 mt-4 color-blk">Your Documents</h2>
      <div className="cards">
        <table className="documents-table task_tabl">
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Category</th>
              <th>Submit Date</th>
              <th>Risk Score (%)</th>
              <th>Owned By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={6}>
                  <BeatLoader
                    color={"#000"}
                    loading={loading}
                    cssOverride={override}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </td>
              </tr>
            )}
            {!loading && files.length == 0 && (
              <tr>
                <td colSpan={6}>No files found.</td>
              </tr>
            )}
            {!loading &&
              files.map((file) => (
                <tr key={file._id}>
                  <td>{file.filename}</td>
                  <td>{file.qc_id?.name || "N/A"}</td>
                  <td>{new Date(file.added_date).toLocaleDateString()}</td>
                  <td>
                    {file.risk_score != null
                      ? `${(file.risk_score * 100).toFixed(2)}%`
                      : "N/A"}
                  </td>
                  <td>{file.user_id?.fname || "Unknown"}</td>
                  <td>
                    <button
                      className="button-send"
                      onClick={() => handleViewFile(file._id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-eye"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <br />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Documents;
