import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL, SOCKET_URL } from "../config";
import "../styles/main.css";
import ClipLoader from "react-spinners/ClipLoader";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};

const Task = () => {
  const [completedFiles, setCompletedFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [buyerSeller, setBuyerSeller] = useState("");
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [discussionPoint, setDiscussionPoint] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(AuthContext);
  // State for the Score Details Modal
  const [showScoreDetailsModal, setShowScoreDetailsModal] = useState(false);
  const [currentFileQAData, setCurrentFileQAData] = useState(null);
  const [isLoadingQAData, setIsLoadingQAData] = useState(false);
  const [Loader2, setLoader2] = useState(false);
  let [loading, setLoading] = useState(true);

  const [showModal2, setShowModal2] = useState(false);
  const handleShowModal2 = () => setShowModal2(true);

  const [is_password_required, setis_password_required] = useState("");
  const [url, seturl] = useState("");

  const [isURL, setisURL] = useState(false);
  const [password, setpassword] = useState("");
  const [link_id, setlink_id] = useState("");
  const [file_id, setfile_id] = useState("");
  const [expandedFileIds, setExpandedFileIds] = useState([]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchFiles(page);
  };
  const fetchFileDetailAnswers = async (fileId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/getFileDetailAnswers`,
        {
          file_id: fileId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        // Update only the specific file's details
        const updatedFileData = response.data.data;
        setFileDetails((prevDetails) =>
          prevDetails.map((file) =>
            file._id === fileId
              ? { ...file, ...updatedFileData, isLoading: false }
              : file
          )
        );
      } else {
        console.error(
          "Error fetching file detail answers:",
          response.data.message
        );
        setFileDetails((prevDetails) =>
          prevDetails.map((file) =>
            file._id === fileId ? { ...file, isLoading: false } : file
          )
        );
      }
    } catch (err) {
      console.error("Error fetching file detail answers:", err);
      setFileDetails((prevDetails) =>
        prevDetails.map((file) =>
          file._id === fileId ? { ...file, isLoading: false } : file
        )
      );
    }
  };

  const handleFileCheckboxChange = (file) => {
    setSelectedFiles((prevSelected) => {
      if (prevSelected.includes(file._id)) {
        return prevSelected.filter((id) => id !== file._id);
      } else {
        return [...prevSelected, file._id];
      }
    });
  };

  const handleMoveToReadLine = async () => {
    if (selectedFiles.length > 0) {
      try {
        // Loop through selected files and move each to readline
        for (let fileId of selectedFiles) {
          const response = await axios.post(
            `${BASE_URL}/user/file/moveToReadline`,
            {
              file_id: fileId, // Pass the file ID to the endpoint
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          if (response.data.status === 1) {
            toast.success(`File ${fileId} successfully moved to Readline.`);
          } else {
            toast.error(
              response.data.message ||
                `Failed to move file ${fileId} to Readline.`
            );
          }
        }
      } catch (error) {
        console.error("Error moving files to Readline:", error);
        toast.error(
          error?.response?.data?.message || "Failed to move files to Readline."
        );
      }
    } else {
      toast.warning("Please select at least one file to move.");
    }
  };

  useEffect(() => {
    setLoader2(true);

    const fetchCompletedFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/file/list`,
          {
            page: "1",
            size: "10",
            file_queue: "back-client",
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          setCompletedFiles(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching completed files:", error);
        setError("Failed to fetch completed files");
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/category/list`,
          {
            page: "0",
            size: "10",
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.status === 1) {
          setCategories(response.data.categories);
        } else {
          setError(
            response.data?.data?.message || "Failed to fetch categories"
          );
        }
      } catch (error) {
        setError("Failed to fetch categories");
      }
    };

    fetchFiles(currentPage);
    fetchCompletedFiles();
    fetchCategories();
    //eslint-disable-next-line
  }, [userToken, userRole]);

  const fetchFiles = async (current) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/file/list`,
        {
          page: current,
          size: "10",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        settotalPages(response.data.totalPages);
        const fileList = response.data.data;
        // console.log(response.data);
        const sorted = fileList.sort((a, b) => b.added_date - a.added_date);

        fetchAllFileDetails(sorted);
      } else {
        setError(response.data.message);
        setLoader2(false);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      setError("Failed to fetch files");
    }
  };
  const fetchAllFileDetails = async (fileList) => {
    const fileDetailsPromises = fileList.map(async (file) => {
      const details = await fetchFileDetails(file._id);
      file.istoggle = false;
      return { ...file, ...details };
    });
    const allDetails = await Promise.all(fileDetailsPromises);
    const sorted = allDetails.sort((a, b) => b.added_date - a.added_date);
    setFileDetails(sorted);
    setLoader2(false);
  };
  const fetchFileDetails = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/global/file-details/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const {
          fileAnswerMode,
          categoryName,
          qc_uid,
          discuss_point,
          risk_score,
          full_name,
          submitted_for_analysis,
          file_hash,
        } = response.data.data;

        return {
          buyerSeller: fileAnswerMode,
          category_name: categoryName,
          discuss_point: discuss_point,
          qc_uid: qc_uid,
          risk_score: risk_score || null,
          full_name: full_name || "Unknown",
          submitted_for_analysis,
          file_hash,
        };
      }
    } catch (error) {
      console.error("Error fetching file details:", error);
      return {};
    }
  };

  // Function to fetch existing QA data for a file hash
  const getExistingFileQA = async (fileHash) => {
    setIsLoadingQAData(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/existing-file-qa`,
        { file_hash: fileHash },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoadingQAData(false);

      if (response.data.status === 1 && response.data.data) {
        return response.data.data;
      } else {
        console.error(
          "No QA data found for this file hash:",
          response.data.message
        );
        return null;
      }
    } catch (error) {
      setIsLoadingQAData(false);
      console.error("Error fetching existing file QA:", error);
      return null;
    }
  };

  const handleViewScoreDetails = async (file) => {
    setCurrentFileQAData(null);
    setShowScoreDetailsModal(true);
    setIsLoadingQAData(true);

    const existingQAData = await getExistingFileQA(file.file_hash);

    if (existingQAData) {
      // Update the specific file's risk score in fileDetails
      setFileDetails((prevFileDetails) =>
        prevFileDetails.map((f) =>
          f._id === file._id
            ? { ...f, risk_score: existingQAData.risk_score }
            : f
        )
      );

      file.ai_risk_score = existingQAData.risk_score;
      setCurrentFileQAData(existingQAData);
    }
    setIsLoadingQAData(false);
  };

  const handleDownload = async (file, e) => {
    e.stopPropagation();
    try {
      const response = await axios.get(
        `${BASE_URL}/user/download/${file._id}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    if (event.target.files[0]) {
      setShowModal(true);
    }
  };

  const handleSubmitForAnalysis = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/submit-for-analysis`,
        {
          file_id: file._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error submitting file for analysis:", error);
      setError("Failed to submit file for analysis");
    }
  };

  const toggleFileExpansion = (fileId) => {
    // Set isLoading to true for the file
    setFileDetails((prevDetails) =>
      prevDetails.map((file) =>
        file._id === fileId ? { ...file, isLoading: true } : file
      )
    );
    // Call fetchFileDetailAnswers
    fetchFileDetailAnswers(fileId);

    // Toggle expansion
    setExpandedFileIds((prev) =>
      prev.includes(fileId)
        ? prev.filter((id) => id !== fileId)
        : [...prev, fileId]
    );
  };

  const createLink = async () => {
    if (is_password_required == "yes" && password) {
      LinkAPI();
    } else if (is_password_required == "yes" && !password) {
      toast.error("Please enter password");
    } else {
      LinkAPI();
    }
  };
  const LinkAPI = async () => {
    if (isURL) {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/link/edit`,
          {
            link_id: link_id,
            is_password_required: is_password_required,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchFiles(currentPage);
        } else {
          console.error("Error hashing document:", response.data.message);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error hashing document:", error);
      }
    } else {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/link/create`,
          {
            file_id: file_id,
            is_password_required: is_password_required,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          seturl(response.data.data.lunk_url);
          fetchFiles(currentPage);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error hashing document:", error);
      }
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-3" style={{ marginLeft: "0px" }}>
          <header className="header-flex">
            <div></div>
            <Header />
          </header>

          {/* Active Files Section */}
          <div>
            <div className="container-fluid mt-1">
              <div className="row">
                <div className="col-12">
                  <div className="cards  mt-0 p-4">
                    <h5 className="cards_headings">Active Files</h5>
                    <table className="task_tabl">
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>Open</th>
                          <th>Document name</th>
                          <th>Category</th>
                          <th>Submit Date</th>
                          <th>Current Stage</th>
                          <th>Risk Score(%)</th>
                          <th>Owned by</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Loader2 && (
                          <tr>
                            <td colSpan={9}>
                              <BeatLoader
                                color={"#000"}
                                loading={loading}
                                cssOverride={override}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </td>
                          </tr>
                        )}
                        {!Loader2 && fileDetails.length == 0 && (
                          <tr>
                            <td colSpan={6}>
                              <p>No records found.</p>
                            </td>
                          </tr>
                        )}
                        {/* {fileDetails
                          .sort((a, b) => b.added_date - a.added_date)
                          .map((file) => (
                            <tr key={file._id}>
                              <td>
                                <input
                                  id="activelistlbls"
                                  type="checkbox"
                                  onChange={() =>
                                    handleFileCheckboxChange(file)
                                  }
                                  checked={selectedFiles.includes(file._id)}
                                />
                                <label
                                  htmlFor="activelistlbls"
                                  className="activelistlbl"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-plus"
                                  >
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-check"
                                  >
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                  </svg>
                                </label>
                              </td>
                              <td>{file.filename}</td>
                              <td>{file.category_name || 'N/A'}</td>
                              <td>
                                {new Date(file.added_date).toLocaleDateString()}
                              </td>
                              <td className="btntds">
                                {file.submitted_for_analysis ? (
                                  'Under Review'
                                ) : (
                                  <button
                                    className="btn_td_tabs"
                                    onClick={() =>
                                      handleSubmitForAnalysis(file)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-bar-chart-2"
                                    >
                                      <line
                                        x1="18"
                                        y1="20"
                                        x2="18"
                                        y2="10"
                                      ></line>
                                      <line
                                        x1="12"
                                        y1="20"
                                        x2="12"
                                        y2="4"
                                      ></line>
                                      <line
                                        x1="6"
                                        y1="20"
                                        x2="6"
                                        y2="14"
                                      ></line>
                                    </svg>
                                    Run Full Analysis
                                  </button>
                                )}
                              </td>
                              <td className="btntds">
                                {!file.submitted_for_analysis ? (
                                  // If risk_score exists, show the score inside the button, otherwise just show "View Preview Score"
                                  <button
                                    className="btn_td_tabs"
                                    onClick={() => handleViewScoreDetails(file)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-activity"
                                    >
                                      <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                    </svg>
                                    {file.ai_risk_score != null
                                      ? `Preview Score: ${(
                                          file.ai_risk_score * 100
                                        ).toFixed(2)}%`
                                      : 'View Preview Score'}
                                  </button>
                                ) : file.ai_risk_score != null ? (
                                  `${(file.ai_risk_score * 100).toFixed(2)}%`
                                ) : (
                                  'N/A'
                                )}
                              </td>
                              <td>{file.user_id?.fname || 'Unknown'}</td>
                              <td>
                                <button
                                  className="button-send"
                                  onClick={() => {
                                    handleShowModal2()
                                    setis_password_required(
                                      file.link?.is_password_required
                                    )
                                    setlink_id(file.link?._id)
                                    setfile_id(file._id)
                                    if (file.link?.url) {
                                      setisURL(true)
                                    } else {
                                      setisURL(false)
                                    }

                                    seturl(file.link?.url)
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-send"
                                  >
                                    <line x1="22" y1="2" x2="11" y2="13"></line>
                                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))} */}
                        {fileDetails
                          .sort((a, b) => b.added_date - a.added_date)
                          .map((file) => (
                            <React.Fragment key={file._id}>
                              <tr>
                                <td>
                                  <input
                                    id="activelistlbls"
                                    type="checkbox"
                                    onChange={() =>
                                      handleFileCheckboxChange(file)
                                    }
                                    checked={selectedFiles.includes(file._id)}
                                  />
                                  <label
                                    htmlFor="activelistlbls"
                                    className="activelistlbl"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-plus"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <line
                                        x1="5"
                                        y1="12"
                                        x2="19"
                                        y2="12"
                                      ></line>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-check"
                                    >
                                      <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                  </label>
                                </td>
                                <td
                                  onClick={() => toggleFileExpansion(file._id)}
                                >
                                  {expandedFileIds.includes(file._id) ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-chevron-down"
                                    >
                                      <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-chevron-right"
                                    >
                                      <polyline points="9 6 15 12 9 18"></polyline>
                                    </svg>
                                  )}
                                </td>
                                <td>{file.filename}</td>
                                <td>{file.category_name || "N/A"}</td>
                                <td>
                                  {new Date(
                                    file.added_date
                                  ).toLocaleDateString()}
                                </td>
                                <td className="btntds">
                                  {file.submitted_for_analysis ? (
                                    "Under Review"
                                  ) : (
                                    <button
                                      className="btn_td_tabs"
                                      onClick={() =>
                                        handleSubmitForAnalysis(file)
                                      }
                                    >
                                      Run Full Analysis
                                    </button>
                                  )}
                                </td>
                                <td className="btntds">
                                  {!file.submitted_for_analysis ? (
                                    <button
                                      className="btn_td_tabs"
                                      onClick={() =>
                                        handleViewScoreDetails(file)
                                      }
                                    >
                                      {file.ai_risk_score != null
                                        ? `Preview Score: ${(
                                            file.ai_risk_score * 100
                                          ).toFixed(2)}%`
                                        : "View Preview Score"}
                                    </button>
                                  ) : file.ai_risk_score != null ? (
                                    `${(file.ai_risk_score * 100).toFixed(2)}%`
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>{file.user_id?.fname || "Unknown"}</td>
                                <td>
                                  <button
                                    className="button-send"
                                    onClick={() => {
                                      handleShowModal2();
                                      setis_password_required(
                                        file.link?.is_password_required
                                      );
                                      setlink_id(file.link?._id);
                                      setfile_id(file._id);
                                      setisURL(!!file.link?.url);
                                      seturl(file.link?.url);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-send"
                                    >
                                      <line
                                        x1="22"
                                        y1="2"
                                        x2="11"
                                        y2="13"
                                      ></line>
                                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                    </svg>
                                  </button>
                                </td>
                              </tr>
                              {file.istoggle && (
                                <tr>
                                  <td colSpan="9" className="padzero">
                                    <BeatLoader
                                      color={"#000"}
                                      loading={loading}
                                      cssOverride={override}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </td>
                                </tr>
                              )}
                              {expandedFileIds.includes(file._id) && (
                                <tr>
                                  <td colSpan="9" className="padzero">
                                    <div className="dropdown-details">
                                      <h5 className="mx-1 mb-3 mt-3">
                                        File Details
                                      </h5>

                                      {/* Conditionally render the loader */}
                                      {file.isLoading ? (
                                        <BeatLoader
                                          color={"#000"}
                                          loading={true}
                                          cssOverride={override}
                                          size={10}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      ) : (
                                        <table className="mt-0 ">
                                          <tr>
                                            <td>File Name:</td>
                                            <td>{file.filename}</td>
                                          </tr>
                                          <tr>
                                            <td>ACV:</td>
                                            <td>
                                              {file.acv || "Dummy ACV Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Payment Terms:</td>
                                            <td>
                                              {file.payment_terms ||
                                                "Dummy Payment Terms Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Billing Frequency:</td>
                                            <td>
                                              {file.billing_frequency ||
                                                "Dummy Billing Frequency Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Contract Term:</td>
                                            <td>
                                              {file.contract_term ||
                                                "Dummy Contract Term Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Auto price increase:</td>
                                            <td>
                                              {file.auto_price_increase ||
                                                "Dummy Auto Price Increase Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Price increase %:</td>
                                            <td>
                                              {file.price_increase_percent ||
                                                "Dummy Price Increase % Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Contract start date:</td>
                                            <td>
                                              {file.contract_start_date ||
                                                "Dummy Start Date"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Contract end date:</td>
                                            <td>
                                              {file.contract_end_date ||
                                                "Dummy End Date"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Contract end date (multi-year
                                              deal):
                                            </td>
                                            <td>
                                              {file.contract_end_date_multi_year ||
                                                "Dummy Multi-Year Deal End Date"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Auto-Renew:</td>
                                            <td>
                                              {file.auto_renew ||
                                                "Dummy Auto-Renew Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>On short order form:</td>
                                            <td>
                                              {file.on_short_order_form ||
                                                "Dummy Short Order Form Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Price increase cap - Y/N:</td>
                                            <td>
                                              {file.price_increase_cap ||
                                                "Dummy Price Increase Cap Data"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Price increase cap amount:</td>
                                            <td>
                                              {file.price_increase_cap_amount ||
                                                "Dummy Price Increase Cap Amount"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Security terms:</td>
                                            <td>
                                              {file.security_terms ||
                                                "Dummy Security Terms"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Customer signatory:</td>
                                            <td>
                                              {file.customer_signatory ||
                                                "Dummy Customer Signatory"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Main customer contact:</td>
                                            <td>
                                              {file.main_customer_contact ||
                                                "Dummy Main Customer Contact"}
                                            </td>
                                          </tr>
                                        </table>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                    <br />
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                    {selectedFiles.length > 0 && (
                      <div className="mt-3">
                        <button
                          className="readline-btn"
                          onClick={handleMoveToReadLine}
                        >
                          Move to ReadLine
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Completed Files Section */}
              {completedFiles.length > 0 && (
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="cards" style={{ width: "80vw" }}>
                      <h3>Completed Files</h3>
                      <table>
                        <thead>
                          <tr>
                            <th>Document name</th>
                            <th>Document Type</th>
                            <th>Submit Date</th>
                            <th>Buyer/Seller</th>
                            <th>Risk Score</th>
                            <th>Owned by</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {completedFiles.map((file) => (
                            <tr key={file._id}>
                              <td>{file.filename}</td>
                              <td>{file.qc_id.name || "N/A"}</td>
                              <td>
                                {new Date(file.added_date).toLocaleDateString()}
                              </td>
                              <td>{file.file_answer_mode || "N/A"}</td>
                              <td>
                                {file.risk_score != null
                                  ? `${(file.risk_score * 100).toFixed(2)}%`
                                  : "N/A"}
                              </td>
                              <td>{file.user_id?.fname || "Unknown"}</td>
                              <td className="btntds">
                                <button
                                  onClick={(e) => handleDownload(file, e)}
                                  disabled={!file.filename} // Disable if file is not available
                                  className={
                                    !file.filename
                                      ? "disabled-button btn_td_tabs"
                                      : "btn_td_tabs"
                                  } // Add a CSS class if disabled
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal2 && (
        <>
          <div
            className="modal fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content border-none">
                <div className="modal-body p-0">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Send Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => {
                            setShowModal2(false);
                          }} // Call handleCloseModal to close
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3 d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={is_password_required == "yes" ? true : false}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setis_password_required("yes");
                            } else {
                              setis_password_required("no");
                            }
                          }}
                        ></input>
                        <p className="my-0 mx-2 paspro">Password Protected</p>
                      </div>
                      {is_password_required == "yes" && (
                        <div className="col-12 mt-2">
                          <label className="paslbl">Enter Password</label>
                          <input
                            className="nptpass"
                            type="password"
                            onChange={(e) => setpassword(e.target.value)}
                            placeholder="Please enter password"
                          ></input>
                        </div>
                      )}
                      {url && (
                        <div className="col-12 mt-2">
                          <label className="paslbl">Link</label>
                          <div className="linkgen">{url}</div>
                        </div>
                      )}

                      {url && (
                        <div className="col-12 mt-2 text-right">
                          <button
                            onClick={() => {
                              navigator.clipboard
                                .writeText(url)
                                .then(() => {
                                  // Reset after 2 seconds
                                  toast.success("Copied");
                                })
                                .catch((err) =>
                                  console.error("Failed to copy text: ", err)
                                );
                            }}
                            className="copylinks"
                          >
                            Copy Link
                          </button>
                        </div>
                      )}

                      <div className="col-12 mt-2">
                        <button onClick={createLink} className="go-back mx-0">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => {
              setShowModal2(false);
            }}
          ></div>
        </>
      )}

      {showScoreDetailsModal && (
        <>
          <div
            className="modal fade show"
            role="dialog"
            onClick={() => setShowScoreDetailsModal(false)}
          >
            <div
              className="modal-dialog modal-dialog-centered mx-600"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content border-none">
                <div className="modal-body">
                  <div
                    className="bg-white position-relative p-4"
                    style={{ maxHeight: "70vh", overflowY: "auto" }}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <div className="title_doc mb-1">Score Details</div>
                    </div>

                    {isLoadingQAData ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          height: "100%",
                        }}
                      >
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <>
                        <h2>
                          Risk Score Preview:{" "}
                          {currentFileQAData.risk_score
                            ? (currentFileQAData.risk_score * 100).toFixed(2) +
                              "%"
                            : "N/A"}
                        </h2>
                        <strong>Data based on preliminary analysis</strong>

                        <div>
                          {currentFileQAData.answers &&
                            currentFileQAData.answers.map((answer, index) => (
                              <div key={index} style={{ marginBottom: "1rem" }}>
                                <p>
                                  <strong>Note {index + 1}:</strong> {answer}
                                </p>
                              </div>
                            ))}

                          {/* Add ellipsis after the last note */}
                          {currentFileQAData.answers &&
                            currentFileQAData.answers.length > 0 && (
                              <p
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "1rem",
                                }}
                              >
                                ...
                              </p>
                            )}
                        </div>
                        <div style={{ marginTop: "1rem", color: "gray" }}>
                          <p>Run full analysis to unlock complete risk score</p>
                        </div>
                        <button
                          className="btn"
                          onClick={() => {
                            setShowScoreDetailsModal(false);
                            setCurrentFileQAData(null);
                          }}
                        >
                          Close
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => {
              setShowScoreDetailsModal(false);
              setCurrentFileQAData(null);
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default Task;
